export const emergency = {
  emergencyServicesButtonProps: {
    color: 'hbGreen',
    sx: {
      width: '100%',
      p: 3,
      fontSize: '1.6rem',
      fontWeight: 500,
    },
  },
  groups: [
    {
      id: 'phone',
      title: 'Telephone Services',
      icon: 'fas fa-mobile',
      items: [
        {
          title: 'Lifeline',
          phone: '13 11 14',
          description: 'Free 24/7 crisis support and suicide prevention services.',
        },
        {
          title: 'Suicide Callback Service',
          phone: '1300 659 467',
          description:
            'Free counselling for suicide prevention & mental health via telephone, online & video for anyone affected by suicidal thoughts, 24/7.',
        },
        {
          title: 'Kids Helpline',
          phone: '1800 55 1800',
          description:
            'Free, private, and confidential 24/7 phone and online counselling service for young people aged 5 to 25.',
        },
        {
          title: 'Sunshine Coast Mental Health Service',
          phone: '1300 64 2255',
          description: 'Free adult mental health services, 8am - 4:30pm',
        },
        {
          title: 'Beyond Blue',
          phone: '1300 22 4636',
          description:
            'Information and support to help Australians achieve their best possible mental health, whatever their age and wherever they live.',
        },
        {
          title: 'QLife',
          phone: '1800 184 527',
          description:
            'Provides anonymous and free LGBTI peer support and referral for people in Australia wanting to talk about sexuality, identity, gender, bodies, feelings or relationships, 3pm to midnight every day.',
        },
        {
          title: 'Mensline Australia',
          phone: '1300 78 99 78',
          description:
            'Provide free 24/7 help, support, referrals & counselling services for men via telephone, online and video.',
        },
      ],
    },
    {
      id: 'chat',
      title: 'Online Chat Services',
      icon: 'fas fa-comments',
      items: [
        {
          title: 'Suicide Callback Service',
          chat: 'https://www.suicidecallbackservice.org.au/phone-and-online-counselling/suicide-call-back-service-online-counselling/',
          description:
            'Free counselling for suicide prevention & mental health via telephone, online & video for anyone affected by suicidal thoughts, 24/7.',
        },
        {
          title: 'Lifeline',
          chat: 'https://www.lifeline.org.au/get-help/online-services/crisis-chat',
          description: 'Free 24/7 crisis support and suicide prevention services.',
        },
        {
          title: 'Headspace',
          chat: 'https://headspace.org.au/eheadspace/connect-with-a-clinician/',
          description:
            'Free online and telephone support and counselling to young people 12 - 25 and their families and friends.',
        },
        {
          title: 'Head to Sam the Chatbot',
          chat: 'https://headtohealth.gov.au/sam-the-chatbot/',
          description:
            'Sam the Chatbot is a virtual assistant which provides you with information and to tells you about available services.',
        },
      ],
    },
  ],
}
