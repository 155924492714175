import { surveyShared } from '../shared/survey'

import keyBy from 'lodash/keyBy'

const kprereq = ['interests', 'contains', ['isolation', 'event', 'stress', 'feelings']]

const kcatmap = {
  early: {
    categories: ['k-early'],
  },
  mild: {
    categories: ['k-mild'],
  },
  moderate: {
    categories: ['k-moderate', 'mental-health-practitioners', 'inpatient-mental-health-services'],
  },
  severe: {
    categories: [
      'k-severe',
      'emergency-services',
      'mental-health-practitioners',
      'inpatient-mental-health-services',
    ],
  },
}

const questions = [
  ...surveyShared.questions,
  {
    id: 'emergency',
    type: 'buttons',
    icon: 'fas fa-exclamation-triangle',
    // strings: {
    //   label: 'Are {{ pronoun.they }} in immediate danger or crisis?',
    //   description: 'If so, please view our list of telephone and online chat services:',

    // },

    question: {
      self: 'Are you in immediate danger or crisis?',
      other: 'Are they in immediate danger or crisis?',
    },
    description: 'Please view our list of telephone and online chat services:',
    suffix: 'or click "Next" to continue.',
    // description: 'If so, please view our list of telephone and online chat services:',
    buttons: [
      {
        color: 'hbGreen',
        variant: 'contained',
        to: '/emergency',
        icon: 'fas fa-plus-square',
        label: 'Immediate Help',
      },
    ],
  },
  {
    id: 'categories',
    type: 'categories',
    label: 'Categories',
    icon: 'far fa-stream',
    strings: {
      label: {
        self: 'What are you interested in?',
        other: 'What are they interested in?',
      },
    },
    categoryGroups: [
      {
        title: 'Services',
        categories: [
          'aboriginal-torres-strait-islander-services',
          'child-and-youth-mental-health',
          'community-based-suicide-prevention-services',
          'emergency-services',
          'general-practitioners',
          'inpatient-mental-health-practitioners',
          'lgbtqi',
          'mental-health-practitioners',
          'peer-support',
          'social-support-services',
          'suicide-bereavement-support',
        ],
      },
      {
        title: 'Information',
        categories: [
          'family-and-friends',
          'online-resources-and-information',
          'safety-planning',
          'support-lines',
        ],
      },
    ],
  },
]

export const survey = {
  questions,

  // TODO: move this and the merge to the config loader
  questionsMap: keyBy(questions, 'id'),

  defaults: {
    categories: [
      // 'child-and-youth-mental-health',
      // 'emergency-services',
      // 'lgbtqi',
    ],
  },

  pathways: [
    {
      pathways: ['self', 'other'],
      result: {
        mappedCategories: false,
        finishRedirect: '/search',
      },
      steps: [
        {
          title: 'Emergency Situation?',
          // description: {
          //   self: 'Knowing what suburb or area code you are in will help us find services nearby.',
          //   other:
          //     'Knowing what suburb or area code they are in will help us find services nearby.',
          // },
          questions: [{ id: 'emergency' }],
          // validation: [['location', 'neq', null]],
        },
        {
          title: {
            self: 'Where are you?',
            other: 'Where are they?',
          },
          description: {
            self: 'Knowing what suburb or area code you are in will help us find services nearby.',
            other:
              'Knowing what suburb or area code they are in will help us find services nearby.',
          },
          questions: [{ id: 'location' }, { id: 'radius' }],
          validation: [['location', 'neq', null]],
        },
        {
          title: {
            self: 'About You',
            other: 'About Them',
          },
          description: {
            self: 'Tell us a little bit more so we may connect you to information that meets your needs.',
            other:
              'Tell us a little bit more so we may connect them to information that meets their needs.',
          },
          questions: [{ id: 'age' }, { id: 'gender' }, { id: 'atsi' }, { id: 'sexuality' }],
          mapCategories: [
            {
              question: 'age',
              categories: {
                '0 - 15': { categories: ['child-and-youth-mental-health'] },
              },
            },
            {
              question: 'atsi',
              categories: {
                Yes: { categories: ['aboriginal-torres-strait-islander-services'] },
              },
            },
            {
              question: 'sexuality',
              categories: {
                Yes: { categories: ['lgbtqi'] },
              },
            },
          ],
        },
        {
          title: {
            self: 'What kind of services are you looking for?',
            other: 'What kind of services are they looking for?',
          },
          questions: [{ id: 'categories' }],
          // mapCategories: [{ question: 'interests' }],
          entry: {
            actions: ['applyMappedCategories'],
          },
        },
      ],
      ui: {
        selectedTab: 1,
      },
    },
  ],
  categoryMapping: [
    {
      type: 'interests',
      question: 'interests',
    },
  ],
}
