import { emergency } from './emergency'
import { selfHelp } from './self-help'
import { survey } from './survey'
import { assets } from './assets'
import { mapStyle } from './map'
import { serviceChangeFields } from '../shared/search'
import { covidAlert } from '../shared/covid-alert'
import React from 'react'
import { ReactComponent as AboutSuicideIcon } from './assets/images/head.svg'

const directoryURL = window.REACT_APP_DIRECTORY_URL

const config = {
  options: {
    title: 'iHelp Sunshine Coast',
    categoryMode: 'single',
    combineMode: 'filters',
    defaultCategoryTitle: 'All Services',
    defaultCategorySuffix: 'Services',
  },

  defaultUserData: {
    params: {
      radius: 50,
    },
    survey: {
      radius: 50,
    },
  },

  analytics: {
    app: 'ihelp',
    plugins: [
      // {
      //   provider: 'google-analytics',
      //   // trackingId: 'UA-188798033-1', // AMS Test
      //   trackingId: 'UA-147876591-5',
      //   // customScriptSrc: 'https://www.google-analytics.com/analytics_debug.js',
      //   siteSpeedSampleRate: 100,
      // },
      {
        provider: 'cie-elastic',
        index: 'prod-ams-ihelp-analytics',
        // debug: true,
      },
    ],
    googleAnalytics: {
      containerId: 'GTM-MCR5548',
    },
  },

  meta: {
    templates: {
      titlePrefix: 'iHelp Sunshine Coast',
      title: '',
      description:
        'This Alliance for Suicide Prevention – Sunshine Coast is a group of organisations and individuals who care about combining mental health resources with local connections to meet a real community need. Together we can use our strengths for good.',
    },
  },

  routeOptions: {
    searchRouteHandler: {
      enabled: true,
      defaultRoute: {
        pathTypeName: 'search',
        path: '/search',
      },
    },
    pathOptions: {
      delimiter: '+',
    },
    parameterMaps: {
      state: {
        state: {
          source: 'state',
          map: ['longUrl'],
        },
      },
      council: {
        state: {
          source: 'council',
          map: ['stateUrl'],
        },
        council: {
          source: 'council',
          map: ['councilUrl'],
        },
      },
      locality: {
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      search: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
          multiple: ['urlFormatted', 'longUrl'],
        },
        state: {
          source: 'locality',
          map: ['stateUrl'],
        },
        council: {
          source: 'locality',
          map: ['councilUrl'],
        },
        locality: {
          source: 'locality',
          map: ['url'],
        },
      },
      categories: {
        categories: {
          source: 'categories',
          map: ['longUrl', 'urlFormatted'],
        },
      },
    },
    generatedPaths: {
      search: {
        path: '/search',
        parameterMaps: ['search'],
      },
      about: {
        path: '/about',
        canonical: '/about',
      },
      support: {
        path: '/support',
        canonical: '/support',
      },
      // regions: {
      //   path: '/regions/',
      //   canonical: '/regions',
      // },
      // categories: {
      //   path: '/categories/',
      //   canonical: '/categories',
      // },
      // browseState: {
      //   path: '/regions/:state',
      //   linkType: 'browse',
      //   parameterMaps: ['state'],
      // },
      // browseCouncil: {
      //   path: '/regions/:state/:council',
      //   linkType: 'browse',
      //   parameterMaps: ['council'],
      // },
      // browseLocality: {
      //   path: '/regions/:state/:council/:locality',
      //   linkType: 'browse',
      //   parameterMaps: ['locality'],
      // },
      // browseCategory: {
      //   path: '/categories/:categories',
      //   linkType: 'browse',
      //   parameterMaps: ['categories'],
      // },
      searchCategories: {
        path: '/search/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocality: {
        path: '/search/:state/:council/:locality',
        linkType: 'search',
        parameterMaps: ['search'],
      },
      searchLocalityCategories: {
        path: '/search/:state/:council/:locality/categories/:categories',
        linkType: 'search',
        parameterMaps: ['search'],
      },
    },
  },

  routes: [
    {
      id: 'home',
      path: '/',
      exact: true,
      component: 'StaticMarkdown',
      container: 'ContentPage',
      props: {
        contentId: 'home',
      },
    },
    {
      id: 'search',
      // path: '/search',
      path: [
        '/search',
        '/search/categories/:categories',
        '/search/:state/:council/:locality',
        '/search/:state/:council/:locality/categories/:categories',
      ],
      exact: true,
      component: 'Search',
      search: true,
      meta: {
        views: ['searchRoute'],
        templates: {
          title:
            '{{ search.categoriesSummary }} in {{ search.locationSummary }}',
          description:
            'Search for {{ search.categoriesSummary }} in {{ search.locationSummary }}. Powered by the Community Information Exchange, My Community Directory and My Community Diary.',
        },
      },
      pathConfig: {
        default: {
          redirectToStored: false,
        },
        search: {
          redirectToStored: true,
        },
      },
    },
    {
      id: 'covid-19-information',
      path: '/covid-19-information',
      component: 'SearchStoreRedirect',
      props: {
        to: '/search',
        tab: 0,
        selectedCategories: 'covid-19',
      },
    },
    {
      id: 'covid-19-services',
      path: '/covid-19-services',
      component: 'SearchStoreRedirect',
      props: {
        to: '/search',
        tab: 1,
        selectedCategories: 'covid-19',
      },
    },
    {
      path: '/survey/start/:pathway',
      exact: true,
      component: 'SurveyRedirect',
    },
    {
      path: '/survey',
      component: 'Survey',
      container: 'ContentPage',
      meta: {
        templates: {
          title: 'Questionnaire',
        },
      },
    },
    {
      path: '/emergency',
      component: 'Emergency',
      container: 'ContentPage',
      props: {
        data: emergency,
      },
      meta: {
        templates: {
          title: 'Emergency Help',
        },
      },
    },
    {
      path: '/self-help-resources',
      component: 'SelfHelpBlock',
      container: 'ContentPage',
      props: {
        data: selfHelp,
      },
      meta: {
        templates: {
          title: 'Self Help Resources',
        },
      },
    },
    {
      path: '/support',
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        templates: {
          title: 'Site Support',
        },
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        templates: {
          title: '404 Not Found',
        },
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],

  ui: {
    appBar: {
      elevation: 0,
      // color: 'secondary',
      groups: [
        {
          align: 'left',
          items: [
            {
              component: 'MenuButton',
              props: {
                sx: { px: 2 },
              },
            },
            {
              component: 'AppBarTitle',
              props: {
                logo: 'logo',
                // disableTextTitle: true,
                // disableLogo: true,
                title: [
                  'Support and service information for suicide prevention',
                  'Sunshine Coast',
                ],
                to: '/',
              },
            },
          ],
        },
        {
          align: 'fill',
          items: [],
        },
        {
          align: 'right',
          items: [
            {
              component: 'AppBarButton',
              media: [{ query: 'up', breakpoints: ['sm'], prop: 'render' }],
              props: {
                icon: 'fas fa-sign-in',
                label: 'Sign In',
                href: `${directoryURL}/Account/Login?loginfrom=ihelp.thealliance.org.au`,
                target: '_blank',
                variant: 'text',
                color: 'inherit',
                disableElevation: true,
              },
            },
          ],
        },
      ],
    },

    homeButtons: {
      iconImgSet: {
        'about-sucidie-and-mental-health': {
          src: <AboutSuicideIcon style={{ height: '40px', width: '40px' }} />,
          color: 'white',
          sx: { height: '80px', color: '#feda3b' },
        },
      },
      buttons: [
        {
          id: 'survey-self',
          icon: 'fas fa-user',
          color: 'hbYellow',
          label: 'I need help',
          route: '/survey/start/self',
        },
        {
          id: 'survey-friend',
          color: 'hbBlue',
          icon: 'fas fa-user-friends',
          label: 'I am supporting someone who needs help',
          route: '/survey/start/other',
        },
        {
          id: 'search',
          color: 'hbLightGrey',
          icon: 'fas fa-info-circle',
          label: 'Information & Services',
          route: '/search',
          toTab: 0,
        },
        {
          id: 'emergency',
          color: 'hbGreen',
          icon: 'fas fa-plus-square',
          label: 'Immediate help',
          route: '/emergency',
        },
        {
          id: 'about-sucidie-and-mental-health',
          color: 'hbLightBlue',
          loadiconWithId: true,
          icon: 'fas fa-sharp fa-solid fa-head-side-medical',
          label: 'About Suicide and Mental Health ',
          route:
            'https://www.mycommunitydirectory.com.au/info/about-suicide-and-mental-health',
        },
        {
          id: 'mental-health-and-suicide-during-an-emergency',
          icon: 'fas fa-exclamation-circle',
          color: 'hbLightBlue',
          label: 'Mental health and natural disasters',
          route:
            'https://www.mycommunitydirectory.com.au/info/mental-health-and-suicide-during-an-emergency',
        },
      ],
    },

    footer: {
      sections: [
        {
          theme: 'variants.footer',
          blocks: [
            {
              // component: 'CieBlock',
              component: 'StaticMarkdown',
              container: 'ContentPage',
              gridProps: { xs: 12, md: 4 },
              props: {
                contentId: 'footer-top',
              },
            },
            {
              title: {
                label: 'Menu',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'navigation',
                hide: 'footer',
              },
            },
            {
              title: {
                label: 'Contact',
                divider: true,
              },
              component: 'StaticMenu',
              gridProps: { xs: 12, md: 4 },
              props: {
                menu: 'contact',
                hide: 'footer',
              },
            },
          ],
        },
      ],
    },

    menus: {
      navigation: {
        items: [
          {
            id: 'home',
            label: 'Home',
            icon: 'fas fa-home-alt',
            route: '/',
          },
          {
            type: 'divider',
            hide: ['footer'],
          },
          {
            id: 'about-sucidie-and-mental-health',
            label: 'About Suicide and Mental Health',
            icon: 'fas fa-sharp fa-solid fa-head-side-medical',
            href: 'https://www.mycommunitydirectory.com.au/info/about-suicide-and-mental-health',
          },
          {
            id: 'mental-health-and-suicide-during-an-emergency',
            label: 'Mental health and natural disasters',
            icon: 'fas fa-exclamation-circle',
            href: 'https://www.mycommunitydirectory.com.au/info/mental-health-and-suicide-during-an-emergency',
          },
          {
            id: 'survey-self',
            label: 'I need help',
            icon: 'fas fa-user',
            route: '/survey/start/self',
          },
          {
            id: 'survey-other',
            label: 'I am supporting someone who needs help',
            icon: 'fas fa-user-friends',
            route: '/survey/start/other',
          },
          {
            id: 'search',
            label: 'Information & Services',
            icon: 'fas fa-info-circle',
            route: '/search',
          },
          {
            id: 'emergency',
            label: 'Immediate Help',
            icon: 'fas fa-plus-square',
            route: '/emergency',
          },
          {
            type: 'divider',
            hide: ['footer'],
          },
          {
            id: 'support',
            label: 'Site Support',
            icon: 'fas fa-user-headset',
            route: '/support',
          },
          {
            id: 'signIn',
            label: 'Sign In',
            icon: 'fas fa-sign-in',
            href: `${directoryURL}/Account/Login?loginfrom=ihelp.thealliance.org.au`,
            hide: ['footer'],
          },
          {
            id: 'reset',
            label: 'Clear Settings & Restart',
            icon: 'fas fa-undo',
            function: 'reset',
            hide: ['footer'],
          },
        ],
      },
      contact: {
        items: [
          {
            id: 'web',
            label: 'thealliance.org.au',
            icon: 'far fa-globe',
            href: 'https://thealliance.org.au',
          },
          {
            id: 'phone',
            label: '07 5430 1191',
            icon: 'fas fa-phone',
            href: 'tel:0754301191',
          },
          {
            id: 'email',
            label: 'suicideprevention@usc.edu.au',
            icon: 'fas fa-envelope',
            href: 'mailto:suicideprevention@usc.edu.au',
          },
          {
            id: 'facebook',
            label: '@alliancesunshinecoast',
            icon: 'fab fa-facebook-square',
            href: 'https://www.facebook.com/alliancesunshinecoast',
          },
        ],
      },
    },

    search: {
      desktop: {
        homeButton: {
          show: true,
          path: '/',
        },
        paramsDrawer: {
          breakpoint: 'lg',
        },
        disableParams: true,
      },

      tabs: [
        {
          id: 'information',
          title: 'Information',
          icon: 'fas fa-info-circle',
          component: 'Information',
          map: false,
          options: {
            tabHint: false,
          },
        },
        {
          id: 'services',
          title: 'Services',
          icon: 'fas fa-building',
          component: 'SearchResults',
          map: true,
          options: {
            tabHint: true,
            search: 'services',
          },
        },
        {
          id: 'favourites',
          title: 'Favourites',
          icon: 'fas fa-star',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'favourites',
          },
        },
      ],

      map: {
        style: mapStyle,
        icons: {
          service: {
            markerColor: '#0072bc',
            holeColor: '#004e81',
          },
        },
      },

      parameters: {
        query: {
          id: 'query',
          type: 'query',
          label: 'Search',
          icon: 'far fa-search',
        },
        location: {
          id: 'location',
          type: 'location',
          label: 'Location',
          icon: 'fas fa-map-marker-question',
        },
        radius: {
          id: 'radius',
          type: 'radius',
          label: 'Radius',
          icon: 'far fa-running',
          props: {
            min: 10,
            max: 100,
            defaultValue: 50,
            step: 5,
            marks: [10, 25, 50, 75, 100],
          },
        },
        categories: {
          id: 'categories',
          type: 'categoryButtons',
          label: 'Categories',
          icon: 'far fa-stream',
        },
        age: {
          id: 'age',
          type: 'select',
          label: 'Age',
          options: ['0 - 15', '16 - 25', '26 - 40', '41 - 64', '65+'],
        },
        gender: {
          id: 'gender',
          type: 'select',
          label: 'Gender',
          options: ['Male', 'Female', 'Intersex', 'Transgender'],
        },
      },

      parameterGroups: {
        desktop: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'categories' },
            { param: 'age' },
            { param: 'gender' },
          ],
        },
        mobileSearch: {
          controls: [
            { param: 'query' },
            { param: 'location' },
            { param: 'radius' },
            { param: 'age' },
            { param: 'gender' },
          ],
        },
        mobileCategories: {
          controls: [{ param: 'categories' }],
        },
      },

      resultsList: {
        default: {
          sections: [
            {
              type: 'alertBlock',
              items: [{ component: 'CovidAlert' }],
            },
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusHeaderBlock',
              items: [{ component: 'StatusHeader' }],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'Status' }, { component: 'SiteSupport' }],
            },
          ],
        },
        favourites: {
          sections: [
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [
                { component: 'FavouritesStatus' },
                { component: 'SiteSupport' },
              ],
            },
          ],
        },
      },

      resultItem: {
        service: {
          components: [
            {
              component: 'Header',
              options: {
                type: 'logo',
                showDistance: true,
                showSeparator: true,
              },
            },
            {
              component: 'Title',
            },
            {
              component: 'Details',
              fields: [
                { key: 'operatingDays', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'NetworkMember',
              // hideVariant: 'map',
              options: {
                image: 'allianceMember',
                imageProps: {
                  sx: { maxHeight: 32 },
                },
                networks: [78],
              },
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
            },
            {
              component: 'HealthEngine',
              hideVariant: 'map',
            },
          ],
        },
      },
    },

    print: { isShowPrintButton: true },
  },

  categories: [
    {
      id: 'default',
      title: 'Information and Services',
      search: true,
      show: 'never',
    },
    {
      id: 'covid-19',
      title: 'COVID-19 Mental Health Support',
      search: true,
      show: 'always',
      searchOptions: {
        overrides: {
          filters: {
            // ORs filters from all categories
            categories: 'all',
          },
          queries: {
            services: {
              params: {
                sortField: 'Nickname',
                sortDirection: 'ASC',
                serviceChanges: true,
              },
            },
          },
        },
      },
    },
    {
      id: 'aboriginal-torres-strait-islander-services',
      title: 'Aboriginal and Torres Strait Islander Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [87, 88, 16, 86, 59],
        },
      },
    },
    {
      id: 'child-and-youth-mental-health',
      title: 'Child and Youth Mental Health',
      search: true,
      searchOptions: {
        filter: {
          categories: [122, 124, 43],
        },
      },
    },
    {
      id: 'community-based-suicide-prevention-services',
      title: 'Community Based Suicide Prevention Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [98, 49],
        },
      },
    },
    {
      id: 'emergency-services',
      title: 'Emergency Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [9, 147, 140, 126],
        },
      },
    },
    {
      id: 'family-and-friends',
      title: 'Family and Friends',
      search: false,
    },
    {
      id: 'general-practitioners',
      title: 'General Practitioners',
      search: true,
      searchOptions: {
        filter: {
          categories: [141],
        },
      },
    },
    {
      id: 'inpatient-mental-health-services',
      title: 'Inpatient Mental Health Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [84, 140],
        },
      },
    },
    {
      id: 'lgbtqi',
      title: 'LGBTQI+ Support Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [139],
        },
      },
    },
    {
      id: 'mental-health-practitioners',
      title: 'Mental Health Practitioners',
      search: true,
      searchOptions: {
        filter: {
          categories: [83],
        },
      },
    },
    {
      id: 'online-resources-and-information',
      title: 'Online Resources and Information',
      search: false,
    },
    {
      id: 'peer-support',
      title: 'Peer Support',
      search: true,
      searchOptions: {
        filter: {
          categories: [93, 95],
        },
      },
    },
    {
      id: 'safety-planning',
      title: 'Safety Planning',
      search: false,
    },
    {
      id: 'social-support-services',
      title: 'Social Support Services',
      search: true,
      searchOptions: {
        filter: {
          categories: [131, 93],
        },
      },
    },
    {
      id: 'suicide-bereavement-support',
      title: 'Suicide Bereavement Support',
      search: true,
      searchOptions: {
        filter: {
          categories: [95, 17],
        },
      },
    },
    {
      id: 'support-lines',
      title: 'Support Lines',
      search: false,
      searchOptions: {
        filter: {
          categories: [57],
        },
      },
    },
  ],

  search: {
    location: {
      showLocationDialog: true,
      initLocation: 'dialog',
      disableStateLabel: true,
      filters: {
        councils: [63, 75],
      },
      // filters: [{ state: 'QLD' }],
      defaultLocation: {
        type: 'locality',
        id: 8226,
        default: true,
        label: 'Maroochydore 4558',
        councilId: 63,
        state: 'QLD',
        postcode: '4558',
        lat: -26.65,
        lng: 153.1,
        url: 'Maroochydore',
        councilUrl: 'Sunshine_Coast',
        stateUrl: 'Queensland',
      },
    },

    survey: {
      autoSelectSingleCategory: false,
    },

    queries: [
      {
        id: 'services',
        builder: 'directory',
        parameters: [
          { param: 'page' },
          { param: 'sort', sortField: 'Distance', sortDirection: 'ASC' },
          { param: 'query' },
          // { param: 'categories' },
          { param: 'location' },
          { param: 'radius' },
          { param: 'age' },
          { param: 'gender' },
          { param: 'filters' },
          { param: 'locationRegions', locationRegions: [65, 77] },
        ],
      },
    ],

    searches: {
      services: {
        id: 'services',
        type: 'directory',
        pageSize: 100,
        showSiteSupport: true,
        // resultsHeader: {
        //   type: 'markdown',
        //   prefix: 'results',
        // },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
  },

  assets,
  survey,
  covidAlert,
}

export default config
